import { useState, useEffect } from 'react'
import axios from 'axios'
import { Image } from '.'
import { } from 'react-router-dom'


const Gallery = () => {
    const [images, setImages] = useState([])

    const getImages = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/instagram', {
                params: {
                    database: 'suchomel',
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            setImages(response.data.data.data.splice(0, 6))
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getImages()
    }, [])

    return (
        <section className="section gallery" id='gallery'>
            <div className="background-text"><p>02</p></div>
            <h2 className='section__header'>Galerie</h2>
            <div className="images">
                {images?.map(image => {
                    return (
                        <Image
                            url={image.media_type === "VIDEO" ? image.thumbnail_url : image.media_url}
                            alt={image.caption}
                            key={image.id}
                            onClick={() => window.open(image.permalink, '_blank')}
                        />
                        )
                })}
                {/* <LightGallery plugins={[lgThumbnail, lgZoom]} className='lightgallery' >
                    {images?.map(image => {
                        return <Image url={image.media_url} lightgallery key={image.id} alt={image.caption} />
                    })}
                </LightGallery> */}
            </div>
            <a href='https://www.instagram.com/truhlarstvimichalsuchomel' target='_blank' className='btn btn-secondary'>Zobrazit další</a>
        </section>
    )
}

export default Gallery