
const Image = ({ url, lightgallery, alt, onClick }) => {
    if (lightgallery) {
        return (
            <a href={url}>
                <img src={url} alt={alt} />
            </a>
        )
    }
    return (
        <img src={url} alt={alt} onClick={onClick} />
    )
}

export default Image